<template>
    <div>
      <div v-if="isSuccess" class="bgc-white p-50" :id="getLang() == 'ar' ? 'mainContent' : ''">
        <div id="success" style="text-align:center">
          <img width="200" src="@/assets/static/images/white-logo.png" />
          <h4 style="color:white;">{{ $t('congratulations') +' '+name+'!'+ $t('addClientSuccess') }}</h4>
          <sui-button type="submit" positive class="btn mL-10" style="background-color:#ddd;color:black;margin-top: 10px" @click="finish" :content="$t('ok')" icon="check" label-position="left" />
        </div>
      </div>
      <div v-else class="bgc-white p-50" :id="getLang() == 'ar' ? 'mainContent' : ''">
        <div class="mT-10" style="text-align: center;">
          <img width="500" src="@/assets/static/images/fitness.png" alt="" @dblclick="enterFullscreen" />
        </div>
        <div class="row mT-5 mB-30">
          <div class="col-sm-12">
            <div style="margin: auto;text-align: center;">
              <lang />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h2 class="c-grey-900 mB-10" style="display: inline-block;">{{ $t('basicInformation') }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <input type="text" class="form-control" :placeholder="$t('fullName')" v-model="name" style="font-size: 30;" />
          </div>
          <div class="col-sm-6">
            <input type="tel" class="form-control" @input="handleInput" :placeholder="$t('qID')" v-model="qID" />
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-6">
            <input type="text" class="form-control" :placeholder="$t('mobileNumber')" @input="handleMobileInput" v-model="mobileNumber" />
          </div>
          <div class="col-sm-6">
            <input type="email" class="form-control" :placeholder="$t('email')" v-model="email" />
          </div>
        </div>
        <div class="row mT-50">
          <div class="col-sm-12">
            <h2 class="c-grey-900 mB-10" style="display: inline-block;">{{ $t('medicalInformation') }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q1') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q1" v-model="q1" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q1" v-model="q1" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="q1 == 1">
          <div class="row mT-30">
            <div class="col-sm-12">
              <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q13') }}</h5>
              <div style="display: inline-block;" class="form-check mR-10 mL-10">
                <label>
                  <input type="radio" name="q13" v-model="q13" value="1">
                  <span dir="rtl">{{ $t('yes') }}</span>
                </label>
              </div>
              <div style="display: inline-block;" class="form-check mR-10 mL-10">
                <label>
                  <input type="radio" name="q13" v-model="q13" value="0">
                  <span dir="rtl">{{ $t('no') }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="q13 == 1">
            <div class="row mT-30">
              <div class="col-sm-2">
                <sui-dropdown selection :options="getCoaches()" v-model="personalTrainer" :placeholder="this.$t('personalTrainerName')" />
              </div>
              <div class="col-sm-2">
                <sui-dropdown selection :options="getMediaes()" v-model="media" :placeholder="$t('q14')" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q2') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q2" v-model="q2" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q2" v-model="q2" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q3') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q3" v-model="q3" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q3" v-model="q3" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q4') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q4" v-model="q4" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q4" v-model="q4" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q5') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q5" v-model="q5" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q5" v-model="q5" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q6') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q6" v-model="q6" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q6" v-model="q6" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q7') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q7" v-model="q7" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q7" v-model="q7" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q8') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q8" v-model="q8" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q8" v-model="q8" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q9') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q9" v-model="q9" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q9" v-model="q9" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q10') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q10" v-model="q10" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q10" v-model="q10" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{$t('q11')}}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q11" v-model="q11" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q11" v-model="q11" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-30">
          <div class="col-sm-12">
            <h5 style="display: inline-block;" class="c-grey-900">{{ $t('q12') }}</h5>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q12" v-model="q12" value="1">
                <span dir="rtl">{{ $t('yes') }}</span>
              </label>
            </div>
            <div style="display: inline-block;" class="form-check mR-10 mL-10">
              <label>
                <input type="radio" name="q12" v-model="q12" value="0">
                <span dir="rtl">{{ $t('no') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mT-50">
          <div class="col-sm-12">
            <h2 class="c-grey-900 mB-10" style="display: inline-block;">{{ $t('termsandConditions') }}</h2>
          </div>
        </div>
        <div class="row c-grey-900">
          <div class="col-sm-12">
            <div class="form-check" style="display: inline-block;">
              <label class="form-label form-check-label">
                <input class="form-check-input" v-model="terms" type="checkbox">{{ $t('accepting') }}
                <span class="link" @click="showTerms" style="color: #0f9aee">{{ $t('termsandConditions') }}.</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row c-grey-900 mT-30">
          <div class="col-sm-12">
            <div class="form-check" style="display: inline-block;">
              <label class="form-label form-check-label">
                <input class="form-check-input" v-model="rules" type="checkbox">{{ $t('accepting') }}
                <span class="link" @click="showRules" style="color: #0f9aee">{{ $t('forfitGymRules') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="isError" class="alert alert-danger" style="margin-top:20px" role="alert">
          {{ errorMessage }}
        </div>
        <div class="row mT-30">
          <sui-button type="submit" positive class="btn btn-primary mL-10" :content="$t('confirm')" icon="check" @click="confirm" label-position="left" />
        </div>
        <div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div v-if="getLang() === 'ar'" class="modal-header" style="direction: rtl;">
                <h5 class="modal-title text-right" id="exampleModalLongTitle">{{ $t('signature') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div v-else class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('signature') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <canvas ref="signatureCanvas" width="400" height="200"></canvas>
              </div>
              <div  class="modal-footer" v-if="!loading">
            <button v-if="!loading" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('close') }}</button>
                <button v-if="!loading" type="button" class="btn btn-secondary" @click="clearSignature">{{ $t('clear') }}</button>
                <button v-if="!loading" type="button" class="btn btn-primary" @click="addClient()">{{ $t('confirm') }}</button>
            </div>
          <div v-else class="modal-footer">
              <div><loading /></div>
            </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div class="modal fade bd-example-modal-lg" id="exampleModalLong" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div v-if="getLang() === 'ar'" class="modal-header" style="direction: rtl;">
                <h5 class="modal-title text-right" id="exampleModalLongTitle">{{ isRules ? $t('forfitGymRules') : $t('termsandConditions') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div v-else class="modal-header">
                <!-- Normal header for other languages -->
                <h5 class="modal-title" id="exampleModalLongTitle">{{ isRules ? $t('forfitGymRules') : $t('termsandConditions') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div v-if="isRules">
                  <div>
                    <div v-if="getLang() == 'en'">
                      <ul>
                        <li>Once membership is purchased, you should start within one month.</li>
                        <li>Kindly ensure that after use, weights are promptly returned to their designated racks or proper storage areas.</li>
                        <li>No gym bags on the floor - please use lockers provided.</li>
                        <li>Proper hygiene is required, wear clean clothes while working out. Athletic shoes are required. {{$t('no')}} dress shoes, boots, or sandals are permitted.</li>
                        <li>No food / Glass bottles allowed on the gym floor.</li>
                        <li>The use or soliciting of performance-enhancing drugs is strictly prohibited and will lead to termination of membership.</li>
                        <li>Gym clothes must be worn at all times.</li>
                        <li>Towel service is limited to one towel per person.</li>
                        <li>The locker provided is for day use only; lockers will be emptied at the end of the night.</li>
                        <li>Do not touch or change the control panels (temperature gauges, music panels, or lights, etc.).</li>
                        <li>Entry to staff-only areas or managerial offices is prohibited.</li>
                        <li>Parking lot access cards will remain the property of the gym and must be returned upon termination or freezing of membership.</li>
                        <li>Loss of parking lot access card will incur a penalty and should only be used by members when accessing the gym.</li>
                        <li>Clean the machines after using: members are required to clean each machine after using. Use a paper towel provided on the gym floor.</li>
                        <li>Don’t leave dumbbells on the floor or weight plates on the bar.</li>
                        <li>Return weight plates and dumbbells to their designated places.</li>
                        <li>No moving of gym equipment.</li>
                        <li>Use the equipment properly, ask for assistance from the forfit gym staff if you are unfamiliar with the equipment.</li>
                        <li>Damage or misuse of gym equipment will lead to an evaluation of your membership status and may lead to termination of membership and/or fines.</li>
                        <li>Photography of other members is strictly prohibited without prior consent.</li>
                        <li>Staff are not responsible for your personal belongings.</li>
                        <li>Check with reception if you have left something in the forfit gym.</li>
                        <li>Members must exit the gym promptly before closing time. If you wish to have a shower, finish the workout with ample time before closing.</li>
                      </ul>
                      <p>
                        <strong>FREEZING TERM</strong>
                      </p>
                      <ul>
                        <li>3 Months Subscription: 1-week freeze.</li>
                        <li>6 Months Subscription: 2-weeks’ freeze.</li>
                        <li>1 Year Subscription: 1-month freeze.</li>
                      </ul>
                    </div>
                    <div v-else>
                      <ul>
                        <li>بمجرد شراء العضوية، يجب أن تبدأ في غضون شهر واحد.</li>
                        <li>يرجى التأكد من إعادة الأوزان إلى أرففها المخصصة أو مناطق التخزين المناسبة بعد الاستخدام.</li>
                        <li>لا يُسمح بوضع حقائب النادي على الأرض - يرجى استخدام الخزائن المخصصة.</li>
                        <li>يلزم اتباع النظافة الجيدة، ارتداء ملابس نظيفة أثناء ممارسة التمارين. يتطلب الأمر ارتداء أحذية رياضية، و {{$t('no')}} يُسمح باستخدام أحذية رسمية، أو أحذية طويلة، أو صنادل.</li>
                        <li>لا يُسمح بتناول الطعام أو استخدام زجاجات الزجاج على أرض النادي.</li>
                        <li>يُمنع صارما استخدام أو التماسك بالمواد المحسنة للأداء وسيؤدي إلى إنهاء العضوية.</li>
                        <li>يجب ارتداء ملابس النادي في جميع الأوقات.</li>
                        <li>خدمة المنشفة محدودة إلى منشفة واحدة لكل شخص.</li>
                        <li>تُقدم الخزانة للاستخدام اليومي فقط؛ سيتم تفريغ الخزائن في نهاية الليل.</li>
                        <li>لا تلمس أو تغير لوحات التحكم (مقاييس الحرارة، لوحات الموسيقى، أو الأضواء، إلخ).</li>
                        <li>يُمنع الدخول إلى مناطق العاملين أو المكاتب الإدارية.</li>
                        <li>تظل بطاقات الوصول إلى مواقف السيارات ملكًا للنادي ويجب إعادتها عند إنهاء العضوية أو تجميدها.</li>
                        <li>فقدان بطاقة الوصول إلى مواقف السيارات سيتسبب في فرض غرامة ويجب استخدامها فقط من قبل الأعضاء عند الوصول إلى النادي.</li>
                        <li>نظّف الآلات بعد الاستخدام: يُطلب من الأعضاء تنظيف كل آلة بعد الاستخدام. استخدم منديل ورقي موفر على أرض النادي.</li>
                        <li>لا تترك الدمبلز على الأرض أو أوزان الأثقال على القضيب.</li>
                        <li>أعد أوزان الأثقال والدمبلز إلى أماكنها المخصصة.</li>
                        <li>لا تتحرك بالمعدات الرياضية.</li>
                        <li>استخدم المعدات بشكل صحيح، واطلب المساعدة من موظفي نادي فورفيت إذا كنت غير عارف بالمعدة.</li>
                        <li>التسبب في أضرار أو إساءة استخدام لمعدات النادي ستؤدي إلى تقييم وضع عضويتك وقد تؤدي إلى إنهاء العضوية و/أو فرض غرامات.</li>
                        <li>يُمنع بشكل صارم التصوير لأعضاء آخرين بدون موافقة مسبقة.</li>
                        <li>الموظفين غير مسؤولين عن ممتلكاتك الشخصية.</li>
                        <li>تحقق من الاستقبال إذا كنت قد تركت شيئًا في نادي فورفيت.</li>
                        <li>يجب على الأعضاء مغادرة النادي بسرعة قبل وقت الإغلاق. إذا كنت ترغب في الاستحمام، انهي التمرين في وقت كاف قبل الإغلاق.</li>
                      </ul>
                      <p>
                        <strong>فترة التجميد</strong>
                      </p>
                      <ul>
                        <li>اشتراك لمدة 3 أشهر: تجميد لمدة أسبوع واحد.</li>
                        <li>اشتراك لمدة 6 أشهر: تجميد لمدة أسبوعين.</li>
                        <li>اشتراك لمدة سنة: تجميد لمدة شهر واحد.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="getLang() == 'en'">
                    <ul>
                      <li>Members can terminate their membership, with no refund of payment being given if they have paid in advance.</li>
                      <li>Members may also terminate their membership with one-month prior notice and must complete the cancellation form. In the absence of such notice, members who pay by credit/Debit card may automatically have their membership renewed.</li>
                      <li>The ForFit Gym Management reserves the right to change the pricing, rules, and terms and conditions of the gym without prior notice.</li>
                      <li>If the contract is terminated for any reason, we reserve the right to retain a proportion of the membership fees that you have paid to us under this agreement to cover any reasonable costs we have incurred.</li>
                      <li>Members expelled will forfeit all privileges of membership.</li>
                      <li>Any complaints unresolved at the time of the incident will be referred to and dealt with by the manager.</li>
                      <li>The gym shall be entitled to make alterations and carry out remedial repairs or maintenance without prior notice. Though of course, every consideration will be made to minimize inconvenience.</li>
                      <li>No refunds for non-usage of gym facilities.</li>
                      <li>Members must adhere to the rules at all times, these are displayed within (not all-encompassing). The gym reserves the right to amend the terms and conditions from time to time.</li>
                    </ul>
                  </div>
                  <div v-else>
                    <ul>
                      <li>يمكن للأعضاء إنهاء عضويتهم دون استرداد للدفع إذا كانوا قد دفعوا مقدماً.</li>
                      <li>يمكن للأعضاء أيضاً إنهاء عضويتهم بإشعار مسبق لمدة شهر ويجب عليهم ملء استمارة الإلغاء. في حالة عدم وجود إشعار كهذا، قد يتم تجديد عضوية الأعضاء الذين يدفعون ببطاقة الائتمان/الخصم تلقائياً.</li>
                      <li>تحتفظ إدارة نادي اللياقة البدنية بحق تغيير التسعير والقواعد والشروط دون إشعار مسبق.</li>
                      <li>إذا تم إنهاء العقد لأي سبب، نحتفظ بحق احتفاظ بجزء من رسوم العضوية التي دفعتها لنا في إطار هذا الاتفاق لتغطية أي تكاليف معقولة قد تكون قد تكبدناها.</li>
                      <li>سيفقد الأعضاء المستبعدين جميع امتيازات العضوية.</li>
                      <li>سيتم إحالة أي شكاوى غير محلولة في وقت الحادث إلى المدير للتعامل معها.</li>
                      <li>يحق للنادي إجراء تعديلات وإجراء إصلاحات تصحيحية أو صيانة دون إشعار مسبق. على الرغم من ذلك، سيتم اتخاذ كل الاعتبارات لتقليل أي إزعاج.</li>
                      <li>لا يتم استرداد أي مبالغ مالية في حال عدم استخدام مرافق النادي الرياضي.</li>
                      <li>يجب على الأعضاء الالتزام بالقواعد في جميع الأوقات، والتي تعرض ضمن (وليس كافة). يحتفظ النادي بحق تعديل الشروط والأحكام من وقت لآخر.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('ok') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
    import SignaturePad from 'signature_pad';
    import html2canvas from 'html2canvas';
    import jsPDF from 'jspdf';
    export default {
      mounted() {
        // Initialize SignaturePad on the canvas element
        this.signaturePad = new SignaturePad(this.$refs.signatureCanvas);
      },
      created() {
        this.getData();
      },
      data() {
        return {
          name: '',
          mobileNumber: '',
          qID: '',
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
          q6: 0,
          q7: 0,
          q8: 0,
          q9: 0,
          q10: 0,
          q11: 0,
          q12: 0,
          q13: 0,
          q14: "",
          rules: 0,
          terms: 0,
          personalTrainer: null,
          isRules: false,
          coaches: [],
          mediaes: [],
          media: null,
          isSuccess: false,
          timerID: -1,
          loading: true,
          isError: false,
          errorMessage: '',
          email: '',
        }
      },
      methods: {
        startTimer() {
          // Set a timer to change isSuccess to false after 15 seconds
          this.timerID = setTimeout(() => {
            this.isSuccess = false;
            this.init();
            setTimeout(() => {
    this.signaturePad = new SignaturePad(this.$refs.signatureCanvas);
  }, 3000);
          }, 15000); // 15 seconds in milliseconds (1000 milliseconds = 1 second)
        },
        stopTimer() {
          // Clear the timeout to stop the timer
          if (this.timerID == -1) {
            return;
          }
          clearTimeout(this.timerID);
        },
        handleInput() {
          // Remove non-numeric characters
          this.qID = this.qID.replace(/\D/g, '');
        },
        handleMobileInput() {
          // Remove non-numeric characters
          this.mobileNumber = this.mobileNumber.replace(/\D/g, '');
        },
        init() {
  this.name = '';
  this.mobileNumber = '';
  this.qID = '';
  this.q1 = 0;
  this.q2 = 0;
  this.q3 = 0;
  this.q4 = 0;
  this.q5 = 0;
  this.q6 = 0;
  this.q7 = 0;
  this.q8 = 0;
  this.q9 = 0;
  this.q10 = 0;
  this.q11 = 0;
  this.q12 = 0;
  this.q13 = 0;
  this.q14 = '';
  this.rules = 0;
  this.terms = 0;
  this.personalTrainer = null;
  this.media = null;
  this.clearSignature();
},

        finish() {
          this.stopTimer();
          this.init();
          this.isSuccess = false;
          setTimeout(() => {
    this.signaturePad = new SignaturePad(this.$refs.signatureCanvas);
  }, 3000);
        },
        async generatePdf() {
          this.loading = true;
          // Convert canvas to image using html2canvas
          var canvas = await html2canvas(this.$refs.signatureCanvas);
          const imgData = canvas.toDataURL('image/png');
          // Initialize jsPDF
          const pdf = new jsPDF({
            orientation: 'landscape'
          });
          pdf.setFontSize(30);
          pdf.text(this.name + ' Signature', 50, 20);
          // Add image to PDF
          pdf.addImage(imgData, 'PNG', 70, 40, 180, 90); // Adjust position and size as needed
          const blob = pdf.output('blob');
          return blob;
        },
        getTranslation: function(ar, en) {
          return localStorage.getItem('lang') == 'ar' ? ar : en;
        },
        getCoaches: function() {
          var coaches = [];
          this.coaches.forEach((coach) => {
            coaches.push({
              text: (this.getTranslation(coach.ar_name, coach.en_name)),
              value: coach.id
            });
          });
          return coaches;
        },
        getMediaes: function() {
          var mediaes = [];
          this.mediaes.forEach((media) => {
            mediaes.push({
              text: (this.getTranslation(media.ar_name, media.en_name)),
              value: media.id
            });
          });
          return mediaes;
        },
        getData: function() {
          this.loading = true;
          this.$http.get("/backend/clients/data", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              key: this.key,
            },
          }).then(function(data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.coaches = data.body.coaches;
                  this.mediaes = data.body.mediaes;
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            });
        },
        validateEmail(email) {
      // Regular expression for validating email addresses
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
        addClient: async function() {
          this.loading = true;
          const blob = await this.generatePdf();
          // Create FormData object
          const formData = new FormData();
          formData.append('signature', blob);
          // Add additional data to FormData
          formData.append('q1', this.q1);
          formData.append('q2', this.q2);
          formData.append('q3', this.q3);
          formData.append('q4', this.q4);
          formData.append('q5', this.q5);
          formData.append('q6', this.q6);
          formData.append('q7', this.q7);
          formData.append('q8', this.q8);
          formData.append('q9', this.q9);
          formData.append('q10', this.q10);
          formData.append('q11', this.q11);
          formData.append('q12', this.q12);
          formData.append('email', this.email); 
          formData.append('personal_trainer_id', this.personalTrainer);
          formData.append('media_id', this.media);
          formData.append('full_name', this.name);
          formData.append('mobile_number', this.mobileNumber);
          formData.append('q_id', this.qID);
          let headers = {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          };
          this.$http.post("/backend/clients/add", formData, {
            headers
          }).then(function(data) {
              this.loading = false;
              switch (data.status) {
                case 201:
                $('#exampleModalCenter').modal('hide');
                this.startTimer();
                  this.isSuccess = true;
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            });
        },
        clearSignature() {
          // Clear the signature
          this.signaturePad.clear();
        },
        enterFullscreen() {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            element.requestFullscreen().catch((err) => {
              console.error('Error attempting to enable fullscreen:', err);
            });
          }
        },
        showRules() {
          this.rules = this.rules == 0 ? 1 : 0
          this.isRules = true;
          $('#exampleModalLong').modal('show')
        },
        showTerms() {
          this.terms = this.terms == 0 ? 1 : 0
          this.isRules = false;
          $('#exampleModalLong').modal('show')
        },
        confirm() {
            if(!this.checkString(this.name)) {
                this.isError = true;
                this.errorMessage = this.$t('nameError')
                return;
            }
            else if(this.qID.length != 11) {
            this.isError = true;
            this.errorMessage = this.$t('qIDError')
            return;
            }
            else if(!this.validateEmail(this.email)) {
              this.isError = true;
              this.errorMessage = this.$t('emailError');
              return;
          } else if(this.q13 == 1) {
            if(this.personalTrainer == null) {
                this.isError = true;
            this.errorMessage = this.$t('noPersonalTrainerSelected')
            return;
            }
            else if(this.media == null) {
                this.isError = true;
            this.errorMessage = this.$t('noTrainingMethodSelected')
            return;
            }
          }else if(this.mobileNumber.length != 8) {
            this.isError = true;
            this.errorMessage = this.$t('unvalidNumber')
            return;
          }else if(!this.terms | !this.rules) {
            this.isError = true;
            this.errorMessage = this.$t('termsError')
            return;
          }

          this.isError = false;
          $('#exampleModalCenter').modal('show')
        },
        getLang() {
          return localStorage.getItem('lang');
        },
        checkString(str) {
  // Remove leading and trailing white spaces
  str = str.trim();
  this.name = str;

  // Split the string by spaces
  const words = str.split(/\s+/);

  // Check if the array length is exactly 3
  if (words.length >= 2) {
    return true;
  } else {
    return false;
  }
},
        error: function(message) {
          this.$toast.error(message, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        success: function() {
          this.$toast.success(this.$t("success"), {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
      }
    }
  </script>
  <style>
    .link:hover {
      cursor: pointer;
      /* Change the cursor to a pointer on hover */
      text-decoration: underline;
      /* Optionally, add an underline effect */
      color: blue;
    }
  
    canvas {
      border: 1px solid #000;
      background-color: #ddd;
    }
  
    #mainContent {
      text-align: right;
      direction: rtl;
    }
  
    label {
      display: block;
      text-align: center;
    }
  
    #success {
      width: 40%;
      background-color: #5cb85c;
      padding: 10px 50px;
      margin: auto;
      margin-top: 100px;
      border: 2px solid #5cb85c;
      /* Add border with the same color as background */
      border-radius: 20px;
      /* Add border radius */
    }
  </style>